import React from 'react';
import { graphql, Link } from 'gatsby';

import environment from '../config/environment';
import Layout from '../components/layout';
import BlogIndexSection from '../components/blog-index-section';
import Hero from '../components/hero';
import SVG from '../components/svg';

const IndexPage = ({
  data: {
    wp,
    allWpCategory,
    allWpPost,
    allWpCaseStudy,
    wpHeader: { HeaderComponentSpecs },
  },
}) => {
  const categories = allWpCategory.edges.map((edge) => edge.node);
  const posts = allWpPost.edges;
  const caseStudies = allWpCaseStudy.edges;
  const contentType = allWpCaseStudy.edges;

  return (
    <Layout
      seo={{
        title: 'Pricyo: Strategie ed esempi pratici per Ecommerce',
        metaDesc: wp.generalSettings.description,
        canonical: environment.HOST,
      }}
    >
      <div className='md:w-2/3 mx-auto space-y-10'>
        <Hero {...HeaderComponentSpecs}></Hero>

        <div className='relative mt-10'>
          <hr />
          <span
            className='absolute pr-3 -top-4 inline-flex items-center space-x-2 text-gray-400 text-lg bg-white font-medium'>
            <SVG
              className='w-5 h-5 stroke-current'
              path='M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z'
            ></SVG>
            <span>Articoli</span>
          </span>
        </div>

        <BlogIndexSection
          slug='blog'
          posts={posts.map((edge) => edge.node)}
          totalCount={allWpPost.totalCount}
        >
          <SVG
            className='w-4 h-4 stroke-current'
            path='M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253'
          ></SVG>
        </BlogIndexSection>

        <div className='flex justify-end'>
          <Link to={`/post/`} className='float-right px-3 py-2 inline-flex items-center justify-between space-x-2 text-orange-600 text-base font-bold shadow-none outline-none rounded-md'>
            <span>
              Vedi gli altri {allWpPost.totalCount} {'Articoli'}
            </span>
            <SVG
              className='w-4 h-4 text-orange-600 stroke-current'
              path='M17 8l4 4m0 0l-4 4m4-4H3'
            ></SVG>
          </Link>
        </div>

        <div className='relative mt-10'>
          <hr />
          <span
            className='absolute pr-3 -top-4 inline-flex items-center space-x-2 text-gray-400 text-lg bg-white font-medium'>
            <SVG
              className='w-5 h-5 stroke-current'
              path='M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z'
            ></SVG>
            <span>Case Study</span>
          </span>
        </div>

        <BlogIndexSection
          className='pt-10 md:pt-0'
          title='Case Studies'
          posts={caseStudies.map((edge) => edge.node)}
          totalCount={allWpCaseStudy.totalCount}
        >
        </BlogIndexSection>
        <div className='flex justify-end'>
          <Link to={`/case-study/`} className='float-right px-3 py-2 inline-flex items-center justify-between space-x-2 text-orange-600 text-base font-bold shadow-none outline-none rounded-md'>
            <span>
              Vedi gli altri {allWpCaseStudy.totalCount} {'Case Studies'}
            </span>
            <SVG
              className='w-3 h-3 text-orange-600 stroke-current'
              path='M17 8l4 4m0 0l-4 4m4-4H3'
            ></SVG>
          </Link>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-3 gap-5">{Posts}</div> */}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexPageQuery {
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }
    wpHeader {
      id
      title
      HeaderComponentSpecs {
        title
        subtitle
        hasCta
        heroformid
      }
    }
    allWpCategory {
      edges {
        node {
          id
          name
          slug
          uri
          posts {
            nodes {
              id
            }
          }
          caseStudies {
            nodes {
              id
            }
          }
        }
      }
    }
    allWpPost(limit: 6, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          uri
          title
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          excerpt
          date
          categories {
            nodes {
              name
            }
          }
          internal {
            type
          }
        }
      }
      totalCount
    }
    allWpCaseStudy(limit: 6, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          uri
          title
          author {
            node {
              id
              name
              avatar {
                url
              }
            }
          }
          excerpt
          date
          categories {
            nodes {
              name
            }
          }
          internal {
            type
          }
        }
      }
      totalCount
    }
  }
`;
